import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { StockModel } from "../../Api/Models/stock.model";
import { stockService } from "../../Api/Services/StockService";
import { useInfoModal } from "../../Context/InfoModalProvider";
import { useLoading } from "../../Context/LoadingProvider";
import StockOrder from "./StockOrder";

interface StockListProps {}

const StockList: React.FunctionComponent<StockListProps> = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const [stocks, setStocks] = useState<StockModel[]>([]);
  const { startLoading, finnishLoading } = useLoading();
  const [stockCorrectionProductId, setStockCorrectionProductId] = useState<
    number | null
  >(null);
  const { showInfo } = useInfoModal();

  const stockCorrectionTextRef = useRef<HTMLTextAreaElement | null>(null);
  const stockCorrectionNumberRef = useRef<HTMLInputElement | null>(null);
  const [selectedProductVariation, setSelectedProductVariation] = useState<
    number | null
  >(null);

  const [selectedStockItems, setSelectedStockItems] = useState<StockModel[]>(
    []
  );

  const [showStockItem, setShowStockItem] = useState<StockModel | null>(null);

  useEffect(() => {
    loadStocks();
    //eslint-disable-next-line
  }, []);

  const loadStocks = () => {
    startLoading("get-stock-list");
    stockService
      .listStocks()
      .then((stocks) => {
        setStocks(stocks);
      })
      .finally(() => {
        finnishLoading("get-stock-list");
      });
  };

  const sendStockCorrectionRequest = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      stockCorrectionProductId &&
      stockCorrectionTextRef.current &&
      stockCorrectionNumberRef.current
    ) {
      startLoading("send-stock-correction");

      stockService
        .stockCorrectionRequest(
          stockCorrectionProductId,
          selectedProductVariation,
          +stockCorrectionNumberRef.current.value,
          stockCorrectionTextRef.current?.value
        )
        .then((stocks) => {
          setStockCorrectionProductId(null);
          if (
            stockCorrectionTextRef.current &&
            stockCorrectionNumberRef.current
          ) {
            stockCorrectionTextRef.current.value = "";
            stockCorrectionNumberRef.current.value = "";
          }
          setSelectedProductVariation(null);
          showInfo(t("Stock correction request sent!"));
        })
        .finally(() => {
          finnishLoading("send-stock-correction");
        });
    }
  };

  const closeStockOrder = () => {
    setSelectedStockItems([]);
  };

  const uniqueProductStocks = (): StockModel[] => {
    let uniqueProductStocks: StockModel[] = [];
    stocks.forEach((stock) => {
      if (!uniqueProductStocks.some((x) => x.productId === stock.productId)) {
        uniqueProductStocks.push(stock);
      }
    });

    return uniqueProductStocks;
  };

  if (selectedStockItems.length > 0) {
    return <StockOrder stocks={selectedStockItems} close={closeStockOrder} />;
  }

  return (
    <div className="view-container background-3">
      <div
        className={"modal" + (!stockCorrectionProductId ? " hidden" : "")}
        tabIndex={-1}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal__dialog modal-dialog--full-height">
          <div className="modal__header">
            <div className="modal__heading">
              {t("Stock correction request")}
            </div>
            <div
              className="modal__close"
              onClick={() => {
                setStockCorrectionProductId(null);
                if (stockCorrectionTextRef.current) {
                  stockCorrectionTextRef.current.value = "";
                }
              }}
            >
              ✖
            </div>
          </div>
          <form className="modal__body" onSubmit={sendStockCorrectionRequest}>
            {stocks.filter((x) => x.productId === stockCorrectionProductId)
              .length > 1 ? (
              <>
                <label>{t("Size")}</label>
                <select
                  className="input-field bg-lightgray mb-2"
                  onChange={(e) => setSelectedProductVariation(+e.target.value)}
                  required
                >
                  <option value="">-</option>
                  {stocks
                    .filter((x) => x.productId === stockCorrectionProductId)
                    .map((stock) => (
                      <option
                        key={stock.variationId}
                        value={stock.variationId ?? ""}
                      >
                        {stock.variationName}
                      </option>
                    ))}
                </select>
              </>
            ) : null}
            <label>{t("Updated quantity")}</label>
            <input
              type="number"
              step={1}
              min={0}
              max={9999999}
              ref={stockCorrectionNumberRef}
              placeholder={t("Updated quantity") ?? ""}
              className="input-field bg-lightgray mb-2"
              required
            />
            <label>{t("Message")}</label>
            <textarea
              ref={stockCorrectionTextRef}
              placeholder={t("Message") ?? ""}
              className="bg-lightgray mb-6"
            ></textarea>
            <div className="flex justify-center mb-8 mt-5">
              <button type="submit" className="button button--blue">
                {t("Send")}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div
        className={"modal" + (!showStockItem ? " hidden" : "")}
        tabIndex={-1}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal__dialog modal-dialog--full-height">
          <div className="modal__header">
            <div className="modal__heading">{t("Item detailes")}</div>
            <div
              className="modal__close"
              onClick={() => {
                setShowStockItem(null);
              }}
            >
              ✖
            </div>
          </div>
          <div className="modal__body">
            <div className="stock-list-card">
              <div className="stock-list-card__image mr-3">
                <img
                  src={showStockItem?.productImage ?? ""}
                  style={{ height: "75px", width: "auto" }}
                  alt="Product"
                />
              </div>
              <div
                className="stock-list-card__content"
                style={{ width: "auto" }}
              >
                <div className="stock-list-card__title">
                  {showStockItem?.productName}
                </div>
                {
                  <div className="stock-list-card__info">
                    {stocks
                      .filter((x) => x.productId === showStockItem?.productId)
                      .map((stockVariation) => (
                        <React.Fragment key={stockVariation.variationId}>
                          <div>
                            <strong>{t("New")} {stockVariation.variationName}</strong>
                            &nbsp;
                            {stockVariation.quantity}
                            {t("pcs")}
                            {stockVariation.whStock !== 999999999 && (
                                <>
                                  <br />
                                  <br />
                                  <strong>{t("Central Warehouse stock ")}</strong>
                                  {stockVariation.whStock} {t("pcs")}
                                </>
                            )}
                          </div>
                          {stockVariation.sanitizePossible ? (
                            <div>
                              <strong>
                                {t("Sanitized")} {stockVariation.variationName}
                              </strong>
                              &nbsp;
                              {stockVariation.sanitizedQuantity}
                              {t("pcs")}
                            </div>
                          ) : null}
                          <br />
                        </React.Fragment>
                      ))}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="view-content">
        <div className="heading text-center">{t("Stock level")}</div>

        <div className="">
          {/*<div className="flex justify-center mb-8">
              <div className="select-rounded-wrapper">
                <select>
                  <option value="Warehouse # 1">Warehouse # 1</option>
                  <option value="Warehouse # 2">Warehouse # 2</option>
                </select>
              </div>
          </div>*/}
          <div className="card-list mb-12 limited-height-content">
            {uniqueProductStocks().map((stock, index) => (
              <div className="stock-list-card" key={index}>
                <div className="stock-list-card__image">
                  {stock.productImage && (
                    <img src={stock.productImage} height="50px" alt="Product" />
                  )}
                </div>
                <div className="stock-list-card__content">
                  <div
                    className="stock-list-card__title"
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={() => {
                      setShowStockItem(stock);
                    }}
                  >
                    {lang === "ar" ? stock.productNameAr
                        : lang === "ur" ? stock.productNameUr
                            : lang === "ku" ? stock.productNameKu
                                : stock.productName}
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      width={20}
                      style={{ marginLeft: "5px" }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                  </div>
                  {stock.variationId && (
                    <div className="stock-list-card__info">
                      {stocks
                        .filter((x) => x.productId === stock.productId)
                        .map((stockVariation) => (
                          <div key={stockVariation.variationId}>
                            <strong>{stockVariation.variationName}</strong>
                            &nbsp;
                            {stockVariation.quantity +
                              stockVariation.sanitizedQuantity}
                            pcs
                          </div>
                        ))}
                    </div>
                  )}
                  <p
                    className="link"
                    onClick={() => {
                      setStockCorrectionProductId(stock.productId);
                    }}
                  >
                    {t("Stock correction?")}
                  </p>
                </div>
                <div className="stock-list-card__right">
                  <div className="stock-list-card__pc">
                    {stock.variationId
                      ? stocks
                          .filter((x) => x.productId === stock.productId)
                          .reduce((accumulator, object) => {
                            return (
                              accumulator +
                              object.quantity +
                              object.sanitizedQuantity
                            );
                          }, 0)
                      : stock.quantity + stock.sanitizedQuantity}{" "}
                    {t("pc")}
                  </div>
                  {stock.orderable ? (
                    <button
                      className="button-add"
                      onClick={() => {
                        const selectedStocks = stock.variationId
                          ? stocks.filter(
                              (x) => x.productId === stock.productId
                            )
                          : [stock];

                        setSelectedStockItems(selectedStocks);
                      }}
                    >
                      <i className="icon-add"></i>
                    </button>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center mb-8 mt-5 ">
            <Link to="/stock/orders/list" className="button button--blue">
              {t("Orders history")}
            </Link>
          </div>

          <div className="flex justify-center">
            <Link to="/menu" className="button">
              <i className="icon-arrow-left button__icon button__icon--prefix"></i>
              {t("Back")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockList;
