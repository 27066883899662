import {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { OrderModel, OrderStatus } from "../../../Api/Models/order.model";
import { stockService } from "../../../Api/Services/StockService";
import { useLoading } from "../../../Context/LoadingProvider";

interface StockOrderListProps {}

interface OrderStatusTextColor {
  text: string;
  color: string;
}

const StockOrderList: React.FunctionComponent<StockOrderListProps> = () => {
  const [orders, setOrders] = useState<OrderModel[]>([]);
  const { startLoading, finnishLoading } = useLoading();
  const location = useLocation(); // Hozzáférünk a location state-hez
  const { selectedFilter: initialSelectedFilter } = location.state || {}
  const [selectedFilter, setSelectedFiler] = useState<number>(initialSelectedFilter ?? -1);  // Ha van filter, használjuk
  const [page, setPage] = useState<number>(1);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const lazyLoadingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();
  const divRef = useRef(null);

  const getOrderAndSync = async () => {
    startLoading("get-order-list");
    stockService
        .listStockOrders(page, selectedFilter) // Add selectedFilter to the API request
        .then((orders) => {
          if (page === 1) {
            setOrders(orders);
          } else {
            setOrders((prev) =>
                [...prev, ...orders].filter(
                    (value, index, self) => self.indexOf(value) === index
                )
            );
          }
        })
        .finally(() => {
          finnishLoading("get-order-list");
        });
  };

  useEffect(() => {
    getOrderAndSync();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let callback: CallableFunction | null = null;

    callback = () => {
      getOrderAndSync();
    };

    timeoutRef.current = setTimeout(() => (callback ? callback() : null), 1000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };

    //eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    let callback: CallableFunction | null = null;

    callback = () => {
      getOrderAndSync();
    };

    timeoutRef.current = setTimeout(() => (callback ? callback() : null), 1000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (lazyLoadingTimeoutRef.current) {
        clearTimeout(lazyLoadingTimeoutRef.current);
      }

      lazyLoadingTimeoutRef.current = setTimeout(() => {

        if (
            // @ts-ignore
            divRef.current.scrollTop + divRef.current.clientHeight >=
            // @ts-ignore
            divRef.current.scrollHeight - 10
        ) {
          setPage((prev) => prev + 1);
        }
      }, 300);
    };

    const scrollableDiv = divRef.current;
    // @ts-ignore
    scrollableDiv.addEventListener('scroll', handleScroll);

    return () => {
      // @ts-ignore
      scrollableDiv.removeEventListener('scroll', handleScroll);
      if (lazyLoadingTimeoutRef.current) {
        clearTimeout(lazyLoadingTimeoutRef.current);
      }
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchFilteredOrders = () => {
      setPage(1); // Oldalszámot állítsd vissza 1-re
      getOrderAndSync(); // Frissítse az adatokat a szűrő alapján
    };

    fetchFilteredOrders();
    //eslint-disable-next-line
  }, [selectedFilter]); // Figyeli a selectedFilter állapotváltozását

  const getOrderStatusText = (
    orderStatus: OrderStatus
  ): OrderStatusTextColor => {
    switch (orderStatus) {
      case OrderStatus.PENDING:
        return { text: t("Pending"), color: "refused" };

      case OrderStatus.APPROVED:
        return { text: t("Approved"), color: "approved" };

      case OrderStatus.APPROVED_LVL2:
        return { text: t("Approved"), color: "approved" };

      case OrderStatus.DECLINED:
        return { text: t("Declined"), color: "refused" };

      case OrderStatus.IN_TRANSIT:
        return { text: t("In transit"), color: "approved" };

      case OrderStatus.DELIVERED:
        return { text: t("Delivered"), color: "approved" };

      case OrderStatus.INBOUNDED:
        return { text: t("Inbounded"), color: "approved" };
      default:
        return {
          text: "",
          color: "",
        };
    }
  };

  return (
    <div className="view-container background-3">
      <div className="view-content">
        <div className="heading text-center">{t("Order list")}</div>
        <div className="flex justify-center">
          <div className="pc-bubble mb-4">
            {
              orders.filter(
                (order) =>
                  selectedFilter === -1 || order.status === selectedFilter
              ).length
            }
          </div>
        </div>
        <div>
          <div className="flex justify-between mb-2 items-center gap-2">
            <div className="select-minimal-wrapper mr-2">
              <select
                dir="rtl"
                value={selectedFilter} // Ez beállítja a select értékét a selectedFilter alapján
                onChange={(e) => setSelectedFiler(parseInt(e.target.value))}
              >
                <option value={-1}>{t("All")}</option>
                <option value={OrderStatus.PENDING}>{t("Pending")}</option>
                <option value={OrderStatus.APPROVED}>{t("Approved")}</option>
                <option value={OrderStatus.DECLINED}>{t("Declined")}</option>
                <option value={OrderStatus.IN_TRANSIT}>
                  {t("In Transit")}
                </option>
                <option value={OrderStatus.DELIVERED}>{t("Delivered")}</option>
                <option value={OrderStatus.INBOUNDED}>{t("Inbounded")}</option>
              </select>
            </div>
          </div>
          <div className="card-list mb-12 limited-height-content limited-height-content" ref={divRef}>
            {orders
              .filter(
                (order) =>
                  selectedFilter === -1 || order.status === selectedFilter
              )
              .map((order) => (
                  <Link
                      to={`/stock/orders/${order.id}`}
                      state={{ orderData: order, selectedFilter }}  // A selectedFilter átadása itt fontos
                      className="order-list-card"
                      key={order.id}
                  >
                    <div className="flex">
                      <div className="order-list-card__left" style={{ display: "flex" }}>
                        <div style={{ marginRight: "10px" }}>
                          <img
                              src={order.products[0].image ?? ""}
                              style={{ width: "50px" }}
                              alt="Product"
                          />
                        </div>
                        <div>
                          <div className="stock-list-card__title">
                            {order.products[0].name ?? ""}&nbsp;(
                            {order.products[0]?.quantity}&nbsp;pcs.
                            {order.products[0]?.receivedQuantity ? (
                                <>
                                  &nbsp;&nbsp;{t("Received")}:&nbsp;
                                  {order.products[0].receivedQuantity}&nbsp;{t("Pcs.")}
                                </>
                            ) : null}
                            )
                          </div>
                          <div className="order-list-card__info">
                            {order.deliveryDate ? (
                                <>
                                  <strong>{t("Delivery date")}:</strong> {order.deliveryDate}
                                </>
                            ) : (
                                <>
                                  <strong>{t("Order date")}:</strong> {order.orderDate}
                                </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="order-list-card__right">
                        <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                        >
                          <div
                              className={
                                  "order-list-card__status " + getOrderStatusText(order.status).color
                              }
                          ></div>
                          <span style={{ marginTop: "5px" }}>
          {getOrderStatusText(order.status).text}
        </span>
                        </div>
                      </div>
                    </div>
                  </Link>
              ))}
          </div>
        </div>

        <div className="flex justify-center mt-4">
          <Link to="/stock/list" className="button">
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StockOrderList;
